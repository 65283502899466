.carousel {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}

.carousel-cursor {
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.image {
  width: 240px;
  height: auto;
  display: block;
  border-radius: 10px;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
